import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import CalendarApp from "./pages/calendar/Calendar";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Blog from './pages/blog/Blog';
import Post from "./pages/blog/Post";
import Create from "./pages/blog/Create";


class App extends Component {
  render() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/group/:gid" component={CalendarApp}/>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/blog">
                        <Blog />
                    </Route>
                    <Route exact path="/blog/post/:pid" component={Post} />
                    <Route path="/blog/create">
                        <Create />
                    </Route>
                    <Route exact path="/home"><Home /></Route>
                </Switch>
            </div>
        </Router>

    );
  }
}



export default App;
