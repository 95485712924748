import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";


class Ads extends Component {
    static getAds() {
        var parent = document.getElementById("ads");
        const yaDiv = document.createElement('div');
        yaDiv.setAttribute('id', 'yandex_rtb_R-A-610451-1');
        parent.appendChild(yaDiv);

        const yaScript = document.createElement('script');
        yaScript.setAttribute('type', 'text/javascript');
        yaScript.innerHTML = `(function(w, d, n, s, t) {
        w[n] = w[n] || [];
        w[n].push(function() {
            Ya.Context.AdvManager.render({
                blockId: "R-A-610451-1",
                renderTo: "yandex_rtb_R-A-610451-1",
                async: true
            });
        });
        t = d.getElementsByTagName("script")[0];
        s = d.createElement("script");
        s.type = "text/javascript";
        s.src = "//an.yandex.ru/system/context.js";
        s.async = true;
        t.parentNode.insertBefore(s, t);
    })(this, this.document, "yandexContextAsyncCallbacks");`;
        parent.appendChild(yaScript);
    };
}



export default Ads;
