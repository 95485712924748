import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router
} from "react-router-dom";
import {backend_url, worker} from "../../vk";
import Header from "../Header";
import Link from '@material-ui/core/Link';

import './Post.css';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Ads from "../Ads";

const axios = require('axios').default;

function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth()+1 < 10 ? '0'+(a.getMonth()+1) : a.getMonth()+1;

    var date = a.getDate() < 10 ? '0'+a.getDate() : a.getDate();
    var hour = a.getHours() < 10 ? '0'+a.getHours() : a.getHours();
    var min = a.getMinutes() < 10 ? '0'+a.getMinutes() : a.getMinutes();
    var time = date + '.' + month + '.' + year + ' ' + hour + ':' + min;
    return time;
}

class Post extends Component {
    constructor(props) {
        super(props);
        let param1 = this.props.location.pathname;
        this.pid = param1.split('/')[3];
    }

    state = {
        user: null,
        post: {},
        comments: [],
        comment: '',
        auth: false
    };

    auth = () => {
        if (this.state.user!=null) return;
        if (this.state.auth) return;
        this.setState({auth: true});
        let at = worker.getToken();
        axios.get(backend_url + 'auth/'+at).then(data => {
            data = data.data;
            this.setState({user: data.user});
        });
        this.updatePost();
    };

    updatePost = () => {
        let at = worker.getToken();
        axios.get(backend_url + 'post/'+this.pid+'/'+at).then(data => {
            data = data.data;
            this.setState({post: data, comments: data.comments});
        });
    };

    toggleLike = () => {
        if (this.state.user != null) {
            let at = worker.getToken();
            axios.get(backend_url + 'post/'+this.pid+'/'+at+'/like').then(data => {
                this.updatePost();
            });
        } else {
            window.location.href = '/login';
        }

    };

    sendComment = () => {
        if (this.state.user != null) {
            let at = worker.getToken();
            let txt = this.state.comment;
            this.setState({comment: ''});
            axios.post(backend_url + 'post/'+this.pid+'/comment', {at: at, text: txt}).then(data => {
                this.updatePost();
            });
        } else {
            window.location.href = '/login';
        }
    };

    renderComments = () => {
        let coms = [];
        if (this.state.comments === undefined) {
            document.getElementsByClassName("body").item(0).style.display = 'none';
            document.getElementsByClassName("body-404").item(0).style.display = 'block';
            document.title = 'Алкокалендарь — Ошибка 404';
            return [];
        }
        for (var i = 0; i < this.state.comments.length; i++) {
            var comment = this.state.comments[i];
            coms.push(<div className="comment">
                <img src={comment.author.photo} />
                <div className="comment-container">
                    <p><Link href={'https://vk.com/id'+comment.author.id}>{comment.author.name}</Link></p>
                    <div>{comment.text}</div>
                </div>
            </div>);
        }
        if (!coms.length) {
            return <p className="no-comments">Нет комментариев</p>;
        }
        return coms;
    };

    componentDidMount() {
        Ads.getAds();
        this.auth();
        document.title = 'Алкокалендарь — ' + this.state.post.title;
    }

    render() {
        return (
            <Router>
                <Header user={this.state.user} />
                <div className="body">
                    <div className="main">
                        <h1>{this.state.post.title}</h1>
                        <div className="post-text" dangerouslySetInnerHTML={{__html: this.state.post.text}}></div>
                        <div id="ads"></div>
                    </div>
                    <div className="footer">
                        <p className="footer-hidden">{timeConverter(this.state.post.date)}</p>
                        <p className="footer-hidden">Автор: <Link href={this.state.post.author !== undefined ? 'https://vk.com/id'+this.state.post.author.id : ''}>
                            {this.state.post.author !== undefined ? this.state.post.author.name : ''}</Link></p>
                        <p className="likes">
                            <i className={this.state.post.liked_by_user ? "fas fa-heart liked-heart" : "fas fa-heart"}
                            onClick={this.toggleLike}></i>
                            {this.state.post.liked}
                        </p>
                    </div>
                    <div className="comments">
                        <h2>Комментарии</h2>
                        <div className="comments-container">
                            <div className="comments-inner">
                                {this.renderComments()}
                            </div>
                            <div className="comments-inputs">
                                <TextField fullWidth id="comment" label="Ваш комментарий" variant="outlined"
                                           value={this.state.comment}
                                           onChange={(e) => {
                                               this.setState({comment: e.target.value});
                                               if (!this.state.user) {
                                                   window.location.href = '/login';
                                               }
                                           }} />
                                <Button variant="contained" color="secondary" onClick={() => this.sendComment()}>Отправить</Button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="body-404">
                    <h1>Ошибка 404</h1>
                    <h2>Страница не найдена</h2>
                </div>
            </Router>

        );
    }
}



export default Post;
