import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import CKEditor from 'ckeditor4-react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import './Create.css';
import {backend_url, worker} from "../../vk";
import Header from "../Header";

const axios = require('axios').default;
CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.14.1/full/ckeditor.js';

class Create extends Component {
    state = {
        editorData: '',
        title: '',
        tag: 1,
        user: null
    };

    auth = () => {
        if (this.state.user!=null) return;
        let at = worker.getToken();
        axios.get(backend_url + 'auth/'+at).then(data => {
            data = data.data;
            this.setState({user: data.user});
        });
    };

    sendPost = () => {
        if (!this.state.editorData.length || !this.state.title.length) {
            return;
        }
        let at = worker.getToken();
        axios.post(backend_url + 'create-post', {'at': at, 'title': this.state.title,
        'text': this.state.editorData, 'tag': this.state.tag}).then(() => {
            window.location.href = '/blog';
        });
    };

    render() {
        this.auth();
        document.title = 'Алкокалендарь — Создание статьи';

        return (
            <Router>
                <Header user={this.state.user} />
                <div>
                    <h1>Создание нового поста</h1>
                    <div className="formBody">
                        <TextField
                            id="tag"
                            select
                            label="Раздел"
                            value={this.state.tag}
                            onChange={(e) => this.setState({tag: e.target.value})}
                            variant="outlined">
                            <MenuItem key={1} value={1}>Обзоры</MenuItem>
                            <MenuItem key={0} value={0}>Другое</MenuItem>
                        </TextField>
                        <TextField fullWidth id="title" label="Заголовок" variant="outlined"
                                   value={this.state.title}
                                   onChange={(e) => {
                                       this.setState({title: e.target.value});
                                       if (!this.state.user) window.location.href = '/login';
                                   }} />


                        <CKEditor
                            onChange={evt => {
                                this.setState({editorData: evt.editor.getData()});
                            }}
                            type="classic"
                        />
                        <Button variant="contained" color="secondary" onClick={() => this.sendPost()}>Отправить</Button>
                        </div>
                </div>
            </Router>

        );
    }
}



export default Create;
