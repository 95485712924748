import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Header from "../Header";
import {backend_url, worker} from "../../vk";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import './Blog.css';
import Link from '@material-ui/core/Link';
import Button from "@material-ui/core/Button";

const axios = require('axios').default;


function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth()+1 < 10 ? '0'+(a.getMonth()+1) : a.getMonth()+1;

    var date = a.getDate() < 10 ? '0'+a.getDate() : a.getDate();
    var hour = a.getHours() < 10 ? '0'+a.getHours() : a.getHours();
    var min = a.getMinutes() < 10 ? '0'+a.getMinutes() : a.getMinutes();
    var time = date + '.' + month + '.' + year + ' ' + hour + ':' + min;
    return time;
}

function tagConverter(ind) {
    var m = ['Другое', "Обзоры"];
    return m[ind];
}


class Blog extends Component {
    state = {
        user: null,
        groups: null,
        error: null,
        posts: [],
        auth: false
    };

    auth = () => {
        if (this.state.user!=null) return;
        if (this.state.auth) return;
        this.setState({auth: true});
        let at = worker.getToken();
        axios.get(backend_url + 'auth/'+at).then(data => {
            data = data.data;
            this.setState({user: data.user});
        });
        axios.get(backend_url + 'posts/'+at).then(data => {
            data = data.data;
            this.setState({posts: data.posts});
        });
    };

    renderPosts = () => {
        var p = [];
        for (var i = 0; i < this.state.posts.length; i++) {
            var post = this.state.posts[i];
            p.push(<div className="post">
                <div className="post-text-container">
                    <h2><Link href={"/blog/post/"+post.id}>{post.title}</Link></h2>
                    <p className="post-tag">Раздел: {tagConverter(post.tag)}</p>
                    <p dangerouslySetInnerHTML={{__html: post.preview}} />
                </div>
                <div className="post-feedback">
                    <p><i className={post.liked_by_user ? "fas fa-heart liked-heart" : "fas fa-heart"}></i> {post.liked}</p>
                    <p><i className="fas fa-comment-alt"></i> {post.comments}</p>
                </div>
                <div className="post-footer">
                    <Button className="post-button" pid={post.id} onClick={(e) => {window.location.href = "/blog/post/"+e.target.getAttribute('pid')}}><Link href={"/blog/post/"+post.id} style={{color: 'black', textDecoration: 'none'}}>Читать</Link></Button>
                    <p>Автор: <Link href={'https://vk.com/id'+post.author.id}>{post.author.name}</Link></p>
                    <p>{timeConverter(post.date)}</p>
                </div>
                <hr />
            </div>);
        }
        return p;
    };

    render() {
        this.auth();
        document.title = 'Алкокалендарь — Статьи';

        return (
            <Router>
                <Header user={this.state.user} />
                <div>
                    {this.renderPosts()}
                </div>
                <Fab className="floatButton" color="primary" aria-label="add" onClick={() => window.location.href = '/blog/create'}>
                    <AddIcon />
                </Fab>
            </Router>

        );
    }
}



export default Blog;
