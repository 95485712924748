import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Link from "@material-ui/core/Link";
import './Header.css';

class Header extends Component {

    renderUser = () => {
        if (this.props.user) {
            return <div className="common-header-user">
                <img src={this.props.user.photo} alt={this.props.user.name} />
                <p>{this.props.user.name}</p>
            </div>
        } else {
            return <Link className="common-header-link" href="/login">Войти</Link>
        }
    };

    render() {
        return (
            <Router>
                <div className="common-header">
                    <div className="common-header-left">
                        <div className="common-header-logo">
                            <p>АК<i className="fas fa-wine-glass-alt"></i></p>
                        </div>
                        <div className="common-header-navigation">
                            <Link className="common-header-link" href="/">Главная</Link>
                            <Link className="common-header-link" href="/blog">Статьи</Link>
                        </div>
                    </div>
                    <div className="common-header-right">
                        {this.renderUser()}
                    </div>
                </div>
            </Router>

        );
    }
}



export default Header;
