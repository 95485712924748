import React, {Component, useState} from 'react';
import Calendar from 'react-calendar';
import './Calendar.css';
import {worker, backend_url} from "../../vk";

import Button from '@material-ui/core/Button';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import Ads from "../Ads";

const axios = require('axios').default;

const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноябра',
    'декабря'
];
const english_months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

function formatDate(d) {
    let m = months[d.getMonth()];
    let day = d.getDate();
    let y = d.getFullYear();
    return day + ' ' + m + ' ' + y;
}

function englishifyKey(k) {
    var m = k.split(" ");
    var year = m[2];
    var day = m[0];
    var month = english_months[months.indexOf(m[1])];
    return month + " " + day + ", " + year;
}
// #ff7979 - background hover
let colors = ["#b3c4ff", "#ff9a9a", "#adffab", "#ffcaaa", "#f7b3ff", "#3bdbff", "#ffbc00", "#c364ff", "#179800", "#fc3fff"];
let color_data = {};//{"1 мая 2020 г.": [[0, "Игорь Теплинский"]], "3 мая 2020 г.": [1, 2], "12 мая 2020 г.": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]};
let amount_by_user = {};

function reloadData() {
    if (!Object.keys(color_data).length) return;
    amount_by_user = {};
    for (var key in color_data) {
        var d = color_data[key];
        try {
            d = d.map(el => colors[el[0]]);
        } catch (e) {
            return;
        }
        var e = document.evaluate("//abbr[@aria-label='"+key+"']/..", document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null ).singleNodeValue ||
            document.evaluate("//abbr[@aria-label='"+englishifyKey(key)+"']/..", document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null ).singleNodeValue;
        if (e) {
            var ch = e.querySelector(".wine-glasses");
            if (ch) ch.remove();
            var div = document.createElement("div");
            div.className = "wine-glasses";
            //data-tip="color_data[key][d.indexOf(el)][1]}
            color_data[key].map(el => {
                if (el[2] in amount_by_user) amount_by_user[el[2]] += el[3];
                else amount_by_user[el[2]] = el[3];
            });
            d = d.map(el => '<i data-tip="'+color_data[key][d.indexOf(el)][1]+'" amount="'+color_data[key][d.indexOf(el)][3]+'" class="fas fa-wine-glass-alt wine-glass" style="margin-left: 4px; color: '+el+'" glass-color="'+colors.indexOf(el)+'"></i>');
            div.innerHTML = d.join("");
            e.appendChild(div);
        }
    }
    setHoverListeners();
}

function showTooltip(el) {
    var tooltip = document.getElementById("tooltip");
    tooltip.style.display = 'block';
    var rect = el.getBoundingClientRect();
    tooltip.style.left = rect.x + 'px';
    tooltip.style.top = (rect.y - 35 + window.scrollY) + 'px';
    tooltip.innerText = el.getAttribute('data-tip') + ' ' + el.getAttribute('amount') + '₽';
}
function closeTooltip() {
    var tooltip = document.getElementById("tooltip");
    tooltip.style.display = 'none';
}

function setHoverListeners() {
    var elements = document.getElementsByClassName('wine-glass');
    for (var i = 0; i < elements.length; i++) {
        var el = elements[i];
        el.addEventListener("mouseenter", function( event ) {
            showTooltip(this);
        }, false);
        el.addEventListener('mouseleave', function () {
            closeTooltip();
        }, false);
        el.addEventListener('click', function () {
            showTooltip(this);
            setTimeout(closeTooltip, 1000);
        });
    }
}

function updateData(gid) {
    axios.get(backend_url + "get-data/"+gid).then((data) => {
        color_data = data.data;
        reloadData();
    })
}

function sendDay(date, uid, gid, amount) {
    var d = date.getFullYear();
    var m = date.getMonth()+1;
    var md = date.getDate();
    d += m < 10 ? '0' + m : m;
    d += md < 10 ? '0' + md : md;
    axios.get(backend_url + "new-day?day="+d+"&id="+uid+'&amount='+amount).then(() => {
        updateData(gid);
    })
}


class CalendarApp extends Component {
    constructor(props) {
        super(props);
        let param1 = this.props.location.pathname;
        this.gid = param1.split('/')[2];
        updateData(this.gid);
    }

    wrapperRef = React.createRef();

    state = {
        date: new Date(),
        user: {},
        users: [],
        holidays: [],
        all_achievements: [],
        isListActive: false,
        amount: 0,
        holidayDialog: false,
        statsDialog: false,
        achsDialog: false,
        currentAchsUser: 0
    };

    handleClose = () => {
        this.setState({holidayDialog: false, statsDialog: false, achsDialog: false});
    };

    onChange = date => {
        reloadData();
        if (date > new Date()) return;
        this.setState({ date });
    };

    auth = () => {
        if (this.state.users.length > 0) return;
        let at = worker.getToken();
        axios.get(backend_url + 'auth/'+this.gid+'/'+at).then(data => {
            data = data.data;
            if (data.error && data.error === 'Incorrect gid') {
                window.location.href = '/';
                return;
            }
            this.setState({user: data.user, users: data.users, holidays: data.holidays, all_achievements: data.all_achievements});
        });
    };

    setHoveredDays = (e) => {
        var e = document.evaluate("//i[@glass-color='"+e.target.getAttribute('glass-color')+"']/../..", document, null, XPathResult.ANY_TYPE, null );
        var glass = e.iterateNext();
        while (glass) {
            glass.style.background = '#ffa5a2';
            glass = e.iterateNext();
        }
    };

    unsetHoveredDays = (e) => {
        console.log('unset');
        var e = document.evaluate("//button/div[@class='wine-glasses']/..", document, null, XPathResult.ANY_TYPE, null );
        var glass = e.iterateNext();
        while (glass) {
            glass.style.removeProperty('background');
            glass = e.iterateNext();
        }
    };

    renderHoverlist = () => {
        let res = [];
        for (var i = 0; i < this.state.users.length; i++) {
            var user = this.state.users[i];
            res.push(<div uid={user.id} glass-color={user.color_id} className="hoverlist-user-container" style={{"border": "2px solid " + colors[user.color_id]}} onMouseEnter={this.setHoveredDays} onMouseLeave={this.unsetHoveredDays}>
                <img src={user.photo} alt={user.name} />
                <div className="hoverlist-user-content">
                    <h4>{user.name}</h4>
                    <p style={{fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto'}}><i className="fas fa-wine-glass-alt" style={{"fontSize": "24px", "color": colors[user.color_id]}}></i> {amount_by_user[user.id] || 0}<span style={{fontSize: '16px'}}>₽</span></p>
                </div>
                <p className="achsButton" uindex={i} style={{background: colors[user.color_id]}} onClick={(e) => {
                    this.setState({achsDialog: true, currentAchsUser: parseInt(e.target.getAttribute('uindex'))})}}><i uindex={i} className="fas fa-trophy"></i></p>
            </div>);
        }
        return res;
    };

    renderHolidays = () => {
        let res = [];
        for (var i = 0; i < this.state.holidays.length; i++) {
            res.push(<h3>{this.state.holidays[i]}</h3>);
        }
        return res;
    };

    renderAchievements = () => {
        let res = [];
        var achs = this.state.users[this.state.currentAchsUser] || {'achievements': []};
        achs = achs['achievements'];
        for (var i = 0; i < achs.length; i++) {
            res.push(<div className="achievement"><div className="achievement-icon"><i className="fas fa-trophy"></i></div><div className="achievement-body"><h3>{achs[i][0]}</h3><p>{achs[i][1]}</p></div></div>);
        }
        var achs_names = achs.map(el => el[0]);
        var all_achs = this.state.all_achievements.map(el => el[0]);
        for (var i = 0; i < all_achs.length; i++) {
            if (!achs_names.includes(all_achs[i])) {
                res.push(<div className="achievement achievement-hidden"><div className="achievement-icon achievement-icon-hidden"><i className="fas fa-trophy"></i></div><div className="achievement-body"><h3>{this.state.all_achievements[i][0]}</h3><p>{this.state.all_achievements[i][1]}</p></div></div>);
            }
        }
        return res;
    };

    showStats = () => {
        this.setState({statsDialog: true});
    };

    componentDidMount() {
        document.addEventListener("click", function(){
            setTimeout(reloadData, 200);
        });
        this.auth();
        Ads.getAds();
    }


    render() {


        return (
            <div>
                <Calendar
                    onChange={this.onChange}
                    value={this.state.date}
                />
                <div className="centerButtons">
                    <div className="amountRow">
                        <div className="amountLeft">
                            <Button size="small" onClick={()=>{this.setState({amount:this.state.amount+100})}}>+100</Button>
                            <Button size="small" onClick={()=>{this.setState({amount:this.state.amount+50})}}>+50</Button>
                        </div>
                        <TextField label="Денег потрачено" style={{maxWidth: '150px'}} type="number" value={this.state.amount} onChange={(e) => this.setState({amount: e.target.value})}></TextField>
                        <div className="amountRight">
                            <Button size="small" onClick={()=>{this.setState({amount:this.state.amount-50})}}>-50</Button>
                            <Button size="small" onClick={()=>{this.setState({amount:this.state.amount-100})}}>-100</Button>
                        </div>
                    </div>
                    <Button id="mainButton" variant="contained" color="secondary" onClick={() => sendDay(this.state.date, this.state.user.id, this.gid, this.state.amount)}>
                        Я бухал{this.state.user && this.state.user.sex === 1 ? 'а' : ''} {formatDate(this.state.date)}
                    </Button>
                </div>
                <div id="ads"></div>

                <div style={{display: "none"}}>{setTimeout(reloadData, 200)}</div>


                <div className="hoverlist-container">
                    <div ref={this.wrapperRef} className="hoverlist">
                        <div className="hoverlist-inner">
                            {this.renderHoverlist()}
                        </div>
                    </div>
                    <Button id="homeButton" variant="contained" onClick={() => {window.location.href = '/'}}><i className="fas fa-home"></i></Button>
                    <Button id="hoverButton" variant="contained" onClick={() => {
                        let wrapper = this.wrapperRef.current;
                        wrapper.classList.toggle('is-hoverlist-open');
                    }}>Показать список</Button>
                    <Button id="holidayButton" variant="contained" onClick={() => {this.setState({holidayDialog: true})}}>Какой сегодня повод?</Button>
                </div>

                <Dialog open={this.state.holidayDialog} onClose={this.handleClose}>
                    <DialogTitle>Какой сегодня повод выпить?</DialogTitle>
                    <DialogContent dividers>
                        {this.renderHolidays()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">закрыть</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.achsDialog} onClose={this.handleClose}>
                    <DialogTitle>Достижения участника {(this.state.users[this.state.currentAchsUser] || {'name': ''})['name']}</DialogTitle>
                    <DialogContent dividers>
                        {this.renderAchievements()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.handleClose();
                            this.showStats();
                        }} color="secondary">статистика</Button>
                        <Button onClick={this.handleClose} color="primary">закрыть</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.statsDialog} onClose={this.handleClose}>
                    <DialogTitle>Статистика</DialogTitle>
                    <DialogContent dividers>
                        <h2>Скоро сделаю</h2>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">закрыть</Button>
                    </DialogActions>
                </Dialog>

                <div id="tooltip"></div>
            </div>

        );
    }
}



export default CalendarApp;
