import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './Home.css';
import {backend_url, worker} from "../../vk";

import Link from '@material-ui/core/Link';
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Header from "../Header";
import Ads from "../Ads";

const axios = require('axios').default;

function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
}

class Home extends Component {
    state = {
        user: null,
        groups: null,
        error: null,
        joinDialog: false,
        createDialog: false,
        groupFieldValue: '',
        nameFieldValue: '',
        authChecked: false
    };

    handleClose = () => {
        this.setState({joinDialog: false, createDialog: false});
    };


    renderGroups = () => {
        var res = [];
        if (this.state.groups==null) return res;
        for (var i = 0; i < this.state.groups.length; i++) {
            var row = this.state.groups[i];
            res.push(<TableRow>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.members}/{row.max_members}</TableCell>
                <TableCell><Button gid={row.id} onClick={(e) => {
                    window.location.href = '/group/' + e.target.parentElement.getAttribute('gid');
                }}>Перейти</Button></TableCell>
            </TableRow>);
        }
        return res;
    };

    auth = () => {
        if (this.state.groups!=null) return;
        let at = worker.getToken();
        axios.get(backend_url + 'auth/'+at).then(data => {
            data = data.data;
            this.setState({groups: data.groups, error: data.error, user: data.user});
        });
    };

    componentDidMount() {
        Ads.getAds();
        this.auth();
    }

    render() {

        return (
            <Router>
                <Header user={this.state.user} />
                <div>
                    <h1 id="title">АЛКОКАЛЕНДАРЬ <i className="fas fa-wine-glass-alt main-glass"></i></h1>

                    <div className="content">
                        <div id="ads"></div>
                        <h3 style={{"textAlign": 'left'}}>Список ваших комнат:</h3>

                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%">ID</TableCell>
                                        <TableCell width="60%">Название</TableCell>
                                        <TableCell width="10%">Участники</TableCell>
                                        <TableCell width="10%"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.renderGroups()}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className="bottom-buttons">
                            <Button variant="contained" color="secondary" onClick={() => {
                                if (!this.state.authChecked) worker.checkAuth(() => {this.setState({authChecked: true})}, () => {window.location.href = '/login';});
                                this.setState({joinDialog: true})
                            }}>Войти в комнату</Button>
                            <Button variant="contained" onClick={() => {
                                if (!this.state.authChecked) worker.checkAuth(() => {this.setState({authChecked: true})}, () => {window.location.href = '/login';});
                                this.setState({createDialog: true})
                            }}>Создать комнату</Button>
                        </div>

                        <div className="text">
                            <p><strong>Алкокалендарь</strong> — это групповой алкогольный онлайн календарь, в котором вы можете ежедневно делать отметки о днях, когда потребляли алкоголь.</p>
                            <p className="left">Для тех, кто здесь впервые:</p>
                            <ul className="left">
                                <li>Войдите в комнату по выданному вам другом ID или прямой ссылке.</li>
                                <li>Создайте группу самостоятельно и поделитесь ссылкой с вашими друзьями.</li>
                                <li>Отмечайте дни, в которые употребляли алкоголь на одном календаре с остальными участниками, просматривайте статистику, зарабатывайте достижения.</li>
                            </ul>
                            <p className="left">Так выглядит заполненный календарь в одной из комнат:</p>
                            <img src={process.env.PUBLIC_URL + '/screenshot1.png'} />
                            <p className="left">Подписывайтесь на нашу <Link href="https://vk.com/alcocalendaronline">группу Вконтакте</Link>, чтобы быть в курсе всех новостей!</p>
                        </div>
                    </div>

                    <Dialog open={this.state.joinDialog} onClose={this.handleClose}>
                        <DialogTitle>Войти в комнату</DialogTitle>
                        <DialogContent><DialogContentText>Чтобы войти в комнату, введите её ID в поле ниже.</DialogContentText>
                            <TextField autoFocus margin="dense" label="ID комнаты" type="number" fullWidth
                                onChange={(e) => {this.setState({groupFieldValue: e.target.value})}}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="secondary">
                                ОТМЕНА
                            </Button>
                            <Button onClick={() => {
                                this.handleClose();
                                if (this.state.groupFieldValue.length === 6) {
                                    window.location.href = '/group/' + this.state.groupFieldValue;
                                }
                            }} color="primary">
                                ВОЙТИ
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.createDialog} onClose={this.handleClose}>
                        <DialogTitle>Создать комнату</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Для того, чтобы создать комнату, придумайте ей название. После нажатия кнопки "Создать" ссылка на комнату будет скопирована в ваш буфер обмена.</DialogContentText>
                            <TextField autoFocus margin="dense" label="Название комнаты" type="text" fullWidth
                                       onChange={(e) => {this.setState({nameFieldValue: e.target.value})}}
                            />
                        </DialogContent>
                        <DialogActions >
                            <Button onClick={this.handleClose} color="secondary">
                                ОТМЕНА
                            </Button>
                            <Button onClick={() => {
                                this.handleClose();
                                if (this.state.nameFieldValue.length) {
                                    if (this.state.error != null) {
                                        window.location.href = '/login';
                                    } else {
                                        axios.get(backend_url + 'create-group?name='+this.state.nameFieldValue+'&at='+worker.getToken()).then(function (data) {
                                            copyTextToClipboard(window.location.href + 'group/'+data.data.gid);
                                            window.location.href = '/group/'+data.data.gid;
                                        })
                                    }
                                }
                            }} color="primary">
                                Создать
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Router>

        );
    }
}



export default Home;
