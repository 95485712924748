import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {worker, token_url} from "./vk";

function runApp() {
    let f = ReactDOM.render;
    const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
        f = ReactDOM.hydrate;
    }

    f(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root')
    );
}

const homePage = window.location.pathname.startsWith('/home');

worker.checkAuth(() => {
    runApp();
},
    () => {
        if (!window.location.href.endsWith('login') && window.location.pathname !== '/' && !window.location.pathname.startsWith('/blog') && !homePage) {
            window.location.href = '/login';
        } else {
            if (homePage) window.location.href = "/";
            runApp();
        }
    });



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
