import React, {Component, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Button from "@material-ui/core/Button";

import '../home/Home.css';
import {token_url} from "../../vk";


class Login extends Component {
    render() {
        document.title = 'Алкокалендарь — Вход';

        return (
            <Router>
                <div>
                    <h1>Авторизация</h1>

                    <div className="content">
                        <h3>На данный момент авторизация возможна только через Vk</h3>
                        <Button variant="contained" color="primary" onClick={() => {window.location.href = token_url;}}>Войти через Vk</Button>
                    </div>
                </div>
            </Router>

        );
    }
}



export default Login;
