import Cookies from 'universal-cookie';

const callback_url = 'https://alcocalendar.ru';
export const backend_url = "https://flashlightservice.tk:6869/";
export const token_url = "https://oauth.vk.com/authorize?client_id=7473905&display=page&redirect_uri="+callback_url+"&scope=offline&response_type=token&v=5.103&revoke=1";
const cookies = new Cookies();
const axios = require('axios').default;

class Worker {
    checkAuth = (success, fail) => {
        let at;
        var lochash    = window.location.hash.substr(1),
            mylocation = lochash.substr(lochash.search(/access_token=/))
                .split('&')[0]
                .split('=')[1];
        if (mylocation) {
            at = mylocation;
        }
        if (!at) {
            at = cookies.get("access_token");
        }
        if (!at) {
            return fail();
        } else {
            this.checkToken(at, success, fail);
        }
    };

    checkToken = (token, success, fail) => {
        axios.get(backend_url + "check-token/"+token).then(text => {
            text = text.data.toString();
            if (text === "1") {
                cookies.set('access_token', token, {path: '/', expires: new Date(Date.now()+2592000000000)});
                success();
            }
            else fail();
        })
    };

    getToken = () => {
        return cookies.get("access_token");
    }
}

export const worker = new Worker();

export default {worker, token_url};
